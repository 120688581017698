<template>

    <!-- Header -->
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                <h6 class="h2 d-inline-block mb-0">{{title}}</h6>
                </div>
                <div class="col-lg-6 col-5 text-right">
                    <logout class="btn btn-sm btn-neutral bg-secondary"/>
                </div>
            </div>
            <!-- Card stats -->
            <div class="row">
                <slot></slot>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import LogoutVue from '../User/Logout.vue'
export default {
    name: 'AdminHeaderComponent',
    props: [
        'title'
    ],
    components:{
        'logout':LogoutVue
    }
}
</script>