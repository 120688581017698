<template>
    <!-- Footer -->
    <div class="footer pt-0 align-items-right">
          <div class="col-lg-12 text-center">
            <div class="copyright text-muted">
              &copy; 2021-2022 <a href="https://ews.ng" class="text-muted" target="_blank">by EWS.NG</a> &nbsp;|&nbsp;
              <a href="https://www.farm360.org" class="font-weight-bold" target="_blank">Farm360.org</a>
            </div>
          </div>
    </div>
</template>

<script>
export default {
    name: 'AdminFooterComponent'
}
</script>