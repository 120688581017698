<template>
  <!-- Topnav -->
    <nav class="navbar navbar-top navbar-expand navbar-dark bg-default border-bottom">
      <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <a href="javascript:void(0)" class=" d-xl-none">
            <img src="../../assets/logo.png" style="height:50px">
            </a>
          <!-- Search form -->
          <!-- <form class="navbar-search navbar-search-light form-inline mr-sm-3" id="navbar-search-main">
            <div class="form-group mb-0">
              <div class="input-group input-group-alternative input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-search"></i></span>
                </div>
                <input class="form-control" placeholder="Search" type="text">
              </div>
            </div>
            <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </form> -->
          <!-- Navbar links -->
          <ul class="navbar-nav align-items-center ml-md-auto ">
            <li class="nav-item d-xl-none">
               <ul class="navbar-nav">
                    <li class="nav-item">&nbsp;&nbsp;
                        <router-link to="/dashboard" class="nav-link">
                            <i class="ni ni-tv-2"></i>&nbsp;
                        </router-link>
                        <!-- <router-link to="/branch" class="nav-link">
                            <i class="ni ni-shop"></i>&nbsp;
                        </router-link> -->
                        <router-link to="/cadres" class="nav-link">
                            <i class="ni ni-badge"></i>&nbsp;
                        </router-link>
                        <router-link to="/employees" class="nav-link">
                            <i class="ni ni-circle-08"></i>&nbsp;
                        </router-link>
                        <router-link to="/production" class="nav-link">
                            <i class="ni ni-basket"></i>&nbsp;
                        </router-link>
                        <router-link to="/timesheets" class="nav-link">
                            <i class="ni ni-time-alarm"></i>&nbsp;
                        </router-link>
                    </li>        
                </ul>
            </li>
          </ul>
          <ul class="navbar-nav align-items-center  ml-auto ml-md-0 ">
            <li class="nav-item dropdown">
                <router-link to="/profile" class="nav-link">
                    <div class="media align-items-center">
                        <span class="avatar avatar-sm rounded-circle">
                            <img alt="Image placeholder" src="../../../src/assets/user.svg">
                        </span>
                        <div class="media-body  ml-2  d-none d-lg-block">
                            <span class="mb-0 text-sm font-weight-bold">{{username}}</span>
                        </div>
                    </div>
                </router-link>
              <div class="dropdown-menu  dropdown-menu-right ">
                <div class="dropdown-header noti-title">
                  <h6 class="text-overflow m-0">Welcome!</h6>
                </div>
                <a href="#!" class="dropdown-item">
                  <i class="ni ni-single-02"></i>
                  <span>My profile</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
</template>

<script>
export default {
  name: 'AdminMobileNav',
  data(){
      return{
          username:null,
      }
  },
  mounted(){
        this.username = localStorage.mydata ? JSON.parse(localStorage.mydata).username: 'Guest'
  }
}
</script>

<style scoped>
.navbar-nav>.nav-item>.nav-link{
    display: inline-block;
    font-size: 15px;
    padding-right: 5px;
    padding-left: 5px;
}
.navbar-search .form-control{
    width:150px;
}
</style>
