<template>
    <button @click="logout"> <slot></slot> Logout</button>
</template>

<script>
    export default{
        name: 'Signout',
        methods:{
            logout () {
                localStorage.removeItem('mydata');
                this.$store.commit("authToken", null)
                this.$router.push('/login')
            }
        }
    }
</script>

<style scoped>
    button{
        background: none;
        border: 0;
    }
</style>
