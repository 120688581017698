<template>
    <div>
        <a-sidebar></a-sidebar>
        <div class="main-content" id="panel">
            <a-mobile-sidebar></a-mobile-sidebar>
            <a-header title="FARM 360 - STOCK/RESOURCE MANAGEMENT" class="bg-light text-white">
                <div class="col-lg-7 col-md-10">
                    <h1 class="display-2 text-dark text-capitalize">Dashboard</h1>
                    <p class="text-dark mt-0 mb-5">Manage Employees, Create &amp; manage production batches, record daily resource usage, record mortalities, assign production to employees, and track their work rate all in this web app! </p>
                </div>
            </a-header>
            <a-content>
                <div class="col-sm-6">
                    <stats-card card-sub-title="FARM EMPLOYEES" :cardTitle="employees.length"></stats-card>
                    <stats-card card-sub-title="EMPLOYEE SALARIES THIS MONTH" :cardTitle="'₦ '+salaries"></stats-card>
                    <stats-card card-sub-title="PRODUCED UNITS TILL DATE" :cardTitle="production_units"></stats-card>
                     <stats-card card-sub-title=" ACTIVE PRODUCTION BATCHES" :cardTitle="productions.length"></stats-card>
                </div>
                <div class="col-sm-6">
                    <stats-card card-sub-title="TOTAL COST OF RESOURCES USED" :cardTitle="'₦ '+resource_costs"></stats-card>
                    <stats-card card-sub-title="TOTAL STOCKS LOST/DAMAGED" :cardTitle="mortality_total"></stats-card>                    
                    <stats-card card-sub-title=" TOTAL PRODUCTS FOR SALE" :cardTitle="products.length"></stats-card>
                    <stats-card card-sub-title="TOTAL PAID ORDERS" :cardTitle="orders.length"></stats-card>
                </div>
            </a-content>
            <a-footer></a-footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import AdminContentVue from '../../components/Admin/AdminContent.vue'
import AdminFooterVue from '../../components/Admin/AdminFooter.vue'
import AdminHeaderVue from '../../components/Admin/AdminHeader.vue'
import StatsCardVue from '../../components/Cards/StatsCard.vue'
import AdminMobileNavVue from '../../components/Nav/AdminMobileNav.vue'
import AdminSideBarVue from '../../components/Nav/AdminSideBar.vue'
export default {
    name: 'AdminDashboard',
    components: {
        'a-header': AdminHeaderVue,
        'a-mobile-sidebar': AdminMobileNavVue,
        'a-sidebar': AdminSideBarVue,
        'a-content': AdminContentVue,
        'a-footer': AdminFooterVue,
        'stats-card': StatsCardVue
    },
    mounted(){
        if (this.authToken) {
            this.getBranch()
        }
        else{
            this.$router.push('/login')
        }
    },
    computed : {
        ...mapState({
            authToken : state => state.authToken,
            branch : state => state.branch,
        })
    },
    data() {
        return{
            employees: [],
            productions: [],
            products: [],
            orders: [],
            salaries: 0,
            resource_costs: 0,
            mortality_total: 0,
            production_units: 0
        }
    },
    methods:{
        getBranch(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/branches',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.$store.commit("branch", response.data.data[0])
                this.getEmployees(),
                this.getActiveProductions(),
                this.getSalaryDueMonth(),
                this.getResourceCosts(),
                this.getMortalityTotal(),
                this.getProductionUnits(),
                this.getProducts(),
                this.getOrders()
            })
            .catch(error => {
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
        },
        getEmployees(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/employees',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.employees = response.data.data
            })
            .catch(error => {
                console.log(error)
            })
        },
        getProducts(){
            axios.get(process.env.VUE_APP_BASEURL+'/shop/products',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.products = response.data.data
            })
            .catch(error => {
                console.log(error)
            })
        },
        getOrders(){
            axios.get(process.env.VUE_APP_BASEURL+'/shop/orders?payment_status=paid',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.orders = response.data.data
            })
            .catch(error => {
                console.log(error)
            })
        },
        getActiveProductions(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/production/batches?status=started',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.productions = response.data.data
            })
            .catch(error => {
                console.log(error)
            })
        },
        getSalaryDueMonth(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/production/reports?branch_id='+this.branch.branch_id+'&salaries=yes&month=yes',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.salaries = response.data.data
            })
            .catch(error => {
                console.log(error)
            })
        },
        getResourceCosts(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/production/reports?branch_id='+this.branch.branch_id+'&resource_cost=yes',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.resource_costs = response.data.data
            })
            .catch(error => {
                console.log(error)
            })
        },
        getMortalityTotal(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/production/reports?branch_id='+this.branch.branch_id+'&mortality_total=yes',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.mortality_total = response.data.data
            })
            .catch(error => {
                console.log(error)
            })
        },
        getProductionUnits(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/production/reports?branch_id='+this.branch.branch_id+'&production_units=yes',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.production_units = response.data.data
            })
            .catch(error => {
                console.log(error)
            })
        },
    },
}
</script>