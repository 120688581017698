<template>
    <!-- Page Content -->
    <div class="container-fluid mt--6">
      <div class="row">
        <slot></slot>
      </div>
    </div>
</template>

<script>
export default {
    name: 'AdminPageContentComponent'
}
</script>