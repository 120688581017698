<template>
    <!-- Sidenav -->
    <nav class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white" id="sidenav-main">
        <div class="scrollbar-inner">
        <!-- Brand -->
        <div class="sidenav-header  align-items-center">
            <a href="javascript:void(0)">
            <img src="../../assets/logo.png" style="height:70px">
            </a>
        </div>
        <div class="navbar-inner">
            <!-- Collapse -->
            <div class="collapse navbar-collapse" id="sidenav-collapse-main">
            <!-- Nav items -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <router-link to="/dashboard" class="nav-link">
                        <i class="ni ni-tv-2 text-primary"></i>
                        <span class="nav-link-text">Dashboard</span>
                    </router-link>
                    <!-- <router-link to="/branch" class="nav-link">
                        <i class="ni ni-shop text-primary"></i>
                        <span class="nav-link-text">Branch</span>
                    </router-link> -->
                    <router-link to="/cadres" class="nav-link">
                        <i class="ni ni-badge text-primary"></i>
                        <span class="nav-link-text">Cadre</span>
                    </router-link>
                    <router-link to="/employees" class="nav-link">
                        <i class="ni ni-circle-08 text-primary"></i>
                        <span class="nav-link-text">Employees</span>
                    </router-link>
                    <router-link to="/production" class="nav-link">
                        <i class="ni ni-basket text-primary"></i>
                        <span class="nav-link-text">Production Batch</span>
                    </router-link>
                    <router-link to="/mortalities" class="nav-link">
                        <i class="ni ni-atom text-primary"></i>
                        <span class="nav-link-text">Mortalities</span>
                    </router-link>
                    <router-link to="/utilities" class="nav-link">
                        <i class="ni ni-compass-04 text-primary"></i>
                        <span class="nav-link-text">Resource Usage</span>
                    </router-link>
                    <router-link to="/assignments" class="nav-link">
                        <i class="ni ni-circle-08 text-primary"></i>
                        <span class="nav-link-text">Assignments</span>
                    </router-link>
                    <router-link to="/timesheets" class="nav-link">
                        <i class="ni ni-time-alarm text-primary"></i>
                        <span class="nav-link-text">Timesheets</span>
                    </router-link>
                    <router-link to="/products" class="nav-link">
                        <i class="fa fa-cubes  text-primary"></i>
                        <span class="nav-link-text">Store Products</span>
                    </router-link>
                    <router-link to="/orders" class="nav-link">
                        <i class="fa fa-shopping-basket text-primary"></i>
                        <span class="nav-link-text">Customer Orders</span>
                    </router-link>
                </li>
                
        
            </ul>
            <!-- Divider -->
            <hr class="my-3">
            </div>
        </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'adminSideBar'
}
</script>

<style scoped>
.router-link-active{
    background: #9bffd5;
    padding: 5px;
    font-weight: 900;
}
</style>