<template>
    <div class="card card-stats">
        <!-- Card body -->
        <div class="card-body">
            <div class="row">
            <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">{{cardSubTitle}}</h5>
                <span class="h2 font-weight-bold text-primary mb-0">{{cardTitle}}</span>
            </div>
            <div class="col-auto">
                <div class="icon icon-shape bg-gradient-dark text-white rounded-circle shadow">
                <i class="fas fa-chart-line"></i>
                </div>
            </div>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatsCardComponent',
    props: [
        'cardTitle',
        'cardSubTitle'
    ],
}
</script>